import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CottagesComponent from "../components/cottages"
import CabinsFullWidth from "../components/cabinsFullWidth"
import ContactInfo from "../components/contactInfo"

const Cottages = () => {  

  return (
    <Layout>
      <SEO title="Cottages" />
      <CabinsFullWidth/>
      <CottagesComponent  heading="Cottages" subHeading="For The Perfect Tofino Vacation"/>
      <ContactInfo />
    </Layout>
  )
}

export default Cottages