import React from 'react'
import styled from "styled-components"
import { Link } from "gatsby"

const ContactInfo = () => {
  return (
    <ContactWrapper>
      <Contact>
        <p>To inquire about reservations and vacancies please e-mail, phone or visit the <Link to="/reservations">reservations page</Link>.</p>
        <ul>
          <li>Phone: <a href="tel:250-725-3789">250-725-3789</a></li>
          <li>E-mail: <a href="mailto:info@goldcoasttofino.com">info@goldcoasttofino.com</a></li>
        </ul>
      </Contact>
      <Address>
        <span>Gold Coast Retreat</span><br />
        1338 &amp; 1340 Chesterman Beach Road<br />
        Box 3 <br />
        Tofino, BC V0R 2Z0 <br />
      </Address>
    </ContactWrapper>
  )
}

export default ContactInfo

const ContactWrapper = styled.div`
  padding: 5rem calc((100vw - 1200px) / 2);
  display: grid;
  width: 90%;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  margin: auto;

  @media screen and (max-width: 868px){
    grid-template-columns: 1fr;
    min-height: 500px;
    margin: 0 auto;
    grid-gap: 0;
  }
`
const Contact = styled.div`

  p{
    font-size: 1.2rem;
  }

  ul {
    padding-left: 5%;
  }

  a {
    text-decoration: none;
    color: #1673b5;
  }
`
const Address = styled.p`
  margin-left: 20%;

  span {
    font-weight: 600;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 868px){
    margin-left: 0;
  }
`
