import styled from "styled-components"
import {Link} from "gatsby"

export const Button = styled(Link)`
  background: ${({ primary }) => (primary ? "#093656 " : "#077bf1")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "20px 50px" : "15px 42px")};
  color: #fff;
  font-size: ${({ big }) => (big ? "1.25rem" : "16px")};
  font-family: 'Nunito', sans-serif;
  outline: none;
  min-width: 100px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s !important;
  border-radius: ${({ round }) => (round ? "35px" : "none")};
  text-transform: uppercase;
  text-shadow: 2px 0px 5px black;
  box-shadow: 0 10px 14px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.2);
  

  &:hover {
    background: rgba(55, 58, 60, 0.9);
    color: #fff;
    text-decoration: none;
  }
`