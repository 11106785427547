import React from 'react'
import styled from "styled-components"
import backgroundImageSand from "../assets/images/sand_texture-bg.jpg"
import PageHeader from "../elements/pageHeader"

const Cottages = ({heading, subHeading}) => {
  
  return (
    <>
     <PageHeader header={heading} subHeader={subHeading}/>
      <InfoContainer>
        <ParagraphContainer>
          <Paragraph>
            The sounds of the surf are constant from your cabin or cottage as Chesterman 
            Beach is just a one minute walk down a tree lined path. The conveniences of the 
            village of Tofino are located a short drive or bike ride away.
          </Paragraph>   
          <Paragraph>
            Cottage and studio cabin rates are seasonal, based on double occupancy, per night. 
            Our rates fluctuate depending on time of year, length of stay, number of guests and last minute deals, 
            please e-mail for a quote and availability.
          </Paragraph>
          <Paragraph>
            <ul>
              <li>Shipwreck Studio Cabin $170 - 295 Cdn</li>
              <li>Haidaway Cottage $185 - 325 Cdn</li>
              <li>Ask us about our "Honeymoon Special"... Bubbles, chocolates in complete and total privacy.</li>
            </ul>
          </Paragraph>
          <Paragraph>
            Reservations with a 50% deposit by e transfer to confirm are required. 
            30-day advance cancellation notice required for refund, 
            less $50 processing fee. Tofino cabin accommodation gets very busy in July and August. 
            Please don't arrive this time of year without a reservation.
          </Paragraph>
          </ParagraphContainer>  
        </InfoContainer>
    </>
  )
}

export default Cottages

const InfoContainer = styled.div`
  padding: 5rem calc((100vw - 1200px) / 2);
  background: #fff;
  color: #000;
  background-image: url(${backgroundImageSand});
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  margin-bottom: 0;
  
  @media screen and (max-width: 868px){
    grid-template-columns: 1fr;
  }
`

const ParagraphContainer = styled.div`
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (max-width: 868px){
    grid-template-columns: 1fr;
    min-height: 500px;
  }
`

const Paragraph = styled.p`
  margin-bottom: 3rem;

  h3 {
    margin-bottom: 1rem;
  }

  ul {
    padding-left: 5%;
  }
`

