import React from 'react'
import styled from "styled-components"
import { Button } from '../elements/button';
import cardImageLeft from "../assets/images/shipwreck-ext.jpg"
import cardImageRight from "../assets/images/haida-ext.jpg"

const CabinsFullWidth = () => {
  return (
    <FullWidthContainer>
    <CabinContainer>
        <FullWidthCabinCard css={`background-image: url(${cardImageLeft});`}
        >
          <AboutCard>
            <TextWrap>
              <ProductTitle>Shipwreck Cabin</ProductTitle>
              <ProductSubTitle>Studio</ProductSubTitle>
            </TextWrap>
            <ViewMore to="/shipwreckCabin" primary="true" round="true">
              View Details
            </ViewMore>
          </AboutCard>
        </FullWidthCabinCard>
        
      </CabinContainer>
      <CabinContainer>
        <FullWidthCabinCard css={`background-image: url(${cardImageRight});`}
        >
          <AboutCard>
            <TextWrap>
              <ProductTitle>Haidaway Cottage</ProductTitle>
              <ProductSubTitle>2 Bedroom</ProductSubTitle>
            </TextWrap>
            <ViewMore to="/haidawayCottage" primary="true" round="true">
              View Details
            </ViewMore>
          </AboutCard>
        </FullWidthCabinCard>
      </CabinContainer>
    </FullWidthContainer>
  )
}

export default CabinsFullWidth

const FullWidthContainer = styled.div`
  width: 100%;
  background: #fcfcfc;
  color: #000;
  min-height: 370px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  overflow: hidden;

  @media screen and (max-width: 868px){
    grid-template-columns: 1fr;
    min-height: 500px;
  }
`

const FullWidthCabinCard = styled.div`
  background-position: center;
  background-size: cover;
  border: 1px solid #fff;
  width: 100%;
  height: 400px;
  position: relative;

  -webkit-transition: all .5s ease; /* Safari and Chrome */
  -moz-transition: all .5s ease; /* Firefox */
  -ms-transition: all .5s ease; /* IE 9 */
  -o-transition: all .5s ease; /* Opera */
  transition: all .5s ease;

  &:hover {
    -webkit-transform:scale(1.1); /* Safari and Chrome */
    -moz-transform:scale(1.1); /* Firefox */
    -ms-transform:scale(1.1); /* IE 9 */
    -o-transform:scale(1.1); /* Opera */
    transform:scale(1.1);
  }
`

const CabinContainer = styled.div`
  overflow: hidden; 
  height: 100%;
`

const AboutCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;

  overflow: hidden;

  @media screen and (max-width: 280px){
    padding: 0 1rem;
  }
`

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 120px;
`

const ProductTitle = styled.h3`
  color: #fff;
  font-weight: 400;
  font-size: clamp(1rem, 5vw, 1.7rem);
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  text-shadow: 2px 2px 4px #000000;
`

const ProductSubTitle = styled.h3`
  color: #fff;
  font-weight: 400;
  font-size: clamp(1rem, 5vw, 1.7rem);
  margin-left: 0.5rem;
  text-shadow: 2px 2px 4px #000000;
`

const ViewMore = styled(Button)`
  font-size: 14px;
  position: absolute;
  top: 220px;
`





