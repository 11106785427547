import React from 'react'
import styled from "styled-components"

const PageHeader = ({header, subHeader}) => {
  return (
    <HeaderContainer>
      <Header>
        {header}
      </Header>
      <SubHeader>
        {subHeader}
      </SubHeader>
    </HeaderContainer>
  )
}

export default PageHeader

const HeaderContainer = styled.div`
  padding: 5rem calc((100vw - 1300px) / 2);
  text-align: center;
  color: #000;
  text-transform: uppercase;
  padding-bottom: 0;
`
const Header = styled.h2`  
  font-size: clamp(1.2rem, 7vw, 2.5rem);
  margin-bottom: 1.5rem;
  margin-top: 1rem;
`

const SubHeader = styled.h3`
  font-size: clamp(1.2rem, 5vw, 1.5rem);
  padding-bottom: 0;
  margin-bottom: 0;
`